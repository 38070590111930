// Project link
    import { paths, langNavigator } from './lang'

    export function projectTranslate() {

        // Animation
        fetch(paths[0])
        .then((response) => {
            return response.json()
        })
        .then((data) => {

                // Recover border, spacing
                var count = Object.keys(data).length
                var caseContent = document.querySelectorAll('.article__p')
                var subtitle = document.querySelectorAll(".container-project__subtitle")
                var nSubtitle = count;
                for (const key in data) {
                    if (Object.hasOwnProperty.call(data, key)) {
                        const element = data[key]
                        let countSubtitle = nSubtitle-count;
                        subtitle[countSubtitle].textContent = element.subtitle
                        count--;
                        
                    }
                }
        })
        .catch((err) => {
            console.log(err);
        })

/*         function buttonTranslate() {
            let button = document.querySelectorAll('.container-project__button')
            for (let i = 0; i < button.length; i++) {
                const element = button[i];
                if(element.classList.contains('container-project__button--website')) {
                    langNavigator.langCode == "en" ? element.textContent = "Go to..." : element.textContent = "Voir le site"
                } else {
                    langNavigator.langCode == "en" ? element.textContent = "Learn more..." : element.textContent = "En savoir plus"
                }

                
            }
        }
        buttonTranslate() */
    }
