import gsap from 'gsap/all'
import { hello } from '../content/hello';


function launchAnimation() {

    let aText = ["p", "h3", "h2", "h1", "li", "button", "a"]
    for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        let selector = document.querySelectorAll(`${aText[i]}`)
        // GSAP Animations
    
            for (let i = 0; i < selector.length; i++) {
                    const element = selector[i];
                    gsap.to(element,{duration:0, opacity:0})
                    gsap.to(element,{ease: "power3.out", duration:2, delay:0.5, opacity:1})

                
            }
        }
    
    
}
 export { launchAnimation }