import gsap from 'gsap/all'


function animationTranslation() {
    
    
    let aText = ["p", "h3", "h2", "h1", "li", "button", "a"]
    for (let i = 0; i < aText.length; i++) {
        const element = aText[i];
        let selector = document.querySelectorAll(`${aText[i]}`)
        // GSAP Animations
    
            for (let i = 0; i < selector.length; i++) {
                const element = selector[i];
                gsap.to(element,{ease: "power2.out", duration:0.5, opacity:0})
                gsap.to(element,{ease: "power2.out", duration:0.5, delay:1, opacity:1})
                
            }
        }


        
}

export { animationTranslation }