"use strict"


// Style import
import { spacing } from './style/spacing'
import { font } from './style/font' 

// Content import
import { languages } from './content/lang'
// Interactions
import { interactions } from './interactions'


import { launchAnimation } from './animation/launch'



// Generation of the site
function generation() {

    //Anim 
    launchAnimation()


    // Content generation

    font()
    languages()
    //gradient()
    // Style
    spacing()
    //border()
    //buttons()
    // Color refresh
    // Interactions
    interactions()

    // Check theme text value
    let theme_btn = document.querySelector('.js-dark-mode')
    if(localStorage.getItem('theme') == "light") {
        theme_btn.textContent = "mode sombre"
    } else if(localStorage.getItem('theme') == "dark"){
        theme_btn.textContent = "mode clair"

    }


}

generation()



// Debug

// Spacing values
//import { aSpacing } from 'style/spacing'

// Border values
//import { aBorder } from 'style/border'

// Colors values
//import { colors } from 'style/spacing'
