import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

import { langNavigator, languages, paths } from 'content/lang.js'
import { animationTranslation } from 'animation/translation.js'
import { themeSwitch } from 'style/theme'
import { rN } from 'utils/fcts'
import { classSwitch } from 'style/theme'
import { list } from "postcss";

export var isReload = {
    state: false
}

function interactions() {
    // Burger menu 


    let burger_menu = document.querySelector('.burger-menu')
    let list_nav = document.querySelector('.list_nav')
    let nav = document.querySelector('.nav')


    burger_menu.addEventListener('click', () => {
        gsap.to(nav, {duration: 0.7, left:"0%" })
        gsap.to(list_nav, {opacity:1, delay:0.4})
    })
    let burger_menu_close = document.querySelector('.burger-menu--close')
    burger_menu_close.addEventListener('click', () => {

        gsap.to(nav, {duration: 0.7, left:"100%" })
        gsap.to(list_nav, {opacity:0, delay:0.4})
    })

    // Buttons
    let btn_hero = document.querySelector('.button--hero')
    btn_hero.addEventListener('click', () => {
        scrollWork()
    })
    let nav_work = document.querySelector('.js-work-nav')
    nav_work.addEventListener('click', () => {
        scrollWork()
    })

    function scrollWork() {

        var offsetValue = 83
        gsap.to(window, {ease: "power2.out", duration: 1, scrollTo: {y:"#id_project", offsetY: offsetValue}});
    }

    // Reload
/*     let btn_reload = document.querySelector('.js-reload')
    let tagline = document.querySelector('.tagline')
    var taglineSeen = []
    btn_reload.addEventListener('click', () => {
        fetch(paths[1])
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            var rTagline = rN(0, Object.keys(data.fact).length-1)
            //console.log(Object.keys(taglineSeen).length >= 15);
            while (taglineSeen.includes(rTagline)) {
                if(Object.keys(taglineSeen).length > Object.keys(data.fact).length-1) {
                    rTagline = rN(0, Object.keys(data.fact).length-1)
                    break;
                }
                rTagline = rN(0, Object.keys(data.fact).length-1)
            }
            tagline.textContent = data.fact[rTagline]
            taglineSeen.push(rTagline)
            })
        }) */

    // theme switch
    let theme_btn = document.querySelector('.js-dark-mode')
    theme_btn.addEventListener('click', () => {
        themeSwitch()
        classSwitch()
    })


    // Lang switch
    let lang_btn = document.querySelector('.js-lang')
    lang_btn.addEventListener('click', () => {
        // If it's in english > change to english
        if(langNavigator.langCode == "fr") {
            lang_btn.textContent = "français"
            langNavigator.langCode = "en"
            localStorage.setItem('language', 'en')

        } else 
        // If it's in english > change to french
        if(langNavigator.langCode == "en") {
            lang_btn.textContent = "english"
            langNavigator.langCode = "fr"
            localStorage.setItem('language', 'fr')
        }
        isReload.state = true
        animationTranslation()
        setTimeout(function() {
            languages()
        }, 500);
    })
/*     function scrollDelay() {
        const delSections = document.querySelectorAll(".delayed-section");
        delSections.forEach(section => {
        const containerAnim = gsap.to(section.querySelector(".innerContainer"), {
            y: "30vh",
            ease: "none"
        });
        
        const imageAnim = gsap.to(section.querySelector(".js-scroll"), {
            y: "-30vh",
            ease: "none",
            paused: true
        });
        
        const scrub = gsap.to(imageAnim, {
            progress: 1,
            paused: true,
            ease: "power3",
            duration: parseFloat(section.dataset.scrub) || 1,
            overwrite: true
        });
        
        ScrollTrigger.create({
            animation: containerAnim,
            scrub: true,
            trigger: section,
            start: "top bottom",
            end: "bottom top",
            onUpdate: self => {
            scrub.vars.progress = self.progress;
            scrub.invalidate().restart();
            }
        });
        });

    }
    scrollDelay() */
}

export { interactions }