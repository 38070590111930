// Project link
    import { aSpacing } from '../style/spacing.js'
    import { aBorder } from '../style/border.js' 
    import { langNavigator, paths } from './lang'
    import { aFontFamily, aFontValues } from '../style/font' 
    import { getRealTime} from '../utils/fcts'
    import { colorChanging } from '../style/color'
    import { aComputedRatio } from '../style/font'
    import { rN } from '../utils/fcts'
    import { gsap } from 'gsap/gsap-core'
    function project() {
        fetch(paths[0])
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            var count = Object.keys(data).length;
            // For each project, create element
            let container = document.querySelector('.work')
            // Hue and Lightness are defined outside the loop the get the same value for every element

            // Get current font
            let time = getRealTime()
            let currentFont = aFontFamily[5]
            var oFont = aFontValues.[currentFont]
            var colorHSL = colorChanging()


            function creation(component,container,options) {
                let current = document.createElement(component)
                container.appendChild(current)
                if(options) {
                    current.className = options.className
                    current.textContent = options.textContent
                    current.setAttribute('style', options.style)
                    current.src = options.src
                    current.href = options.href
                }
            }  

            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    
                    const element = data[key];
                    let section = document.querySelector('.section__projects')
                    
                    let divCreateElement = document.createElement('div')
                    let div = section.appendChild(divCreateElement)
                    div.classList.add('container-project')
                    div.style.position = "relative"
                    div.style.marginBottom = aSpacing[5] + "px"
                    div.style.marginTop = aSpacing[3] + "px"
                    var title = creation('h2', div, {
                        className:"container-project__title",
                        textContent: element.name,
                        style: `font-size: 45px; letter-spacing: -0.1rem; margin-bottom:8px; letter-spacing:-2px;`
                    })

                    var subtitle = creation('h3', div, {
                        className:"container-project__subtitle",
                        textContent: element.subtitle,
                        style: `font-size: 22px; margin-bottom:24px; font-weight: 100`
                    })
                    // Background and thumbnail container
                    let containerImgCreateElement = document.createElement('div')
                    let containerImg = div.appendChild(containerImgCreateElement)
                    containerImg.style.position = "relative"

                    var thumbnailCreate = document.createElement('img')
                    var thumbnail = containerImg.appendChild(thumbnailCreate)
                    thumbnail.classList.add('container-project__thumb')
                    thumbnail.style.marginBottom = aSpacing[2] + "px"
                    thumbnail.src = element.src
                    thumbnail.alt = element.alt
                    thumbnail.style.cursor = "pointer";
                    thumbnail.addEventListener('click', () => {
                        let main = document.querySelector('main')
                        let nav = document.querySelector('nav')
                        let projects = document.querySelectorAll('.container-project')
                        console.log(paths[0]);
                        fetch(paths[0])
                        .then((response) => {
                            return response.json()
                        })
                        .then((data) => {
                            const element2 = data[key];
                            
                            for (let i = 0; i < projects.length; i++) {
                                const element = projects[i];
                            }
                            gsap.to(main, {
                                opacity:0,
                                duration:1,
                                display: "none"
                            })
                            let currentBg = containerImg.children[1]
                            gsap.to(currentBg, {
                                height:"0px",
                                duration:0.3
                            })
                            let footer = document.querySelector('footer')

                            let articleCreate = document.createElement('article')
                            let article = footer.parentNode.insertBefore(articleCreate, footer)
                            article.classList.add('article')

                            let thumbnail =  creation('img', article, {
                                className:"article__thumbnail",
                                src: element.src
                            })

                            let articleContainerCreate = document.createElement('div')
                            let articleContainer = article.appendChild(articleContainerCreate)
                            articleContainer.classList.add('article__container')
                            setTimeout(() => {
                                window.scrollTo(0,0)
                            }, 900);


                            let bgArticle = document.createElement('div')
                            let bg = document.body.appendChild(bgArticle)
                            bg.classList.add('background-article')

                            let title_article = creation('h3', articleContainer, {
                                style: `font-weight: bold; 
                                        margin-top: ${aSpacing[1]}px; 
                                        font-size: ${aComputedRatio[3]* oFont.ratio}px`,
                                textContent: element2.name
                            })
                            let subtitle_article = creation('h3', articleContainer, {
                                textContent: element.subtitle,
                                style: `font-size: 18px; margin-bottom:64px; font-weight: medium`
                            })
                            let p1 = creation('p', articleContainer, {
                                className: 'base-font article__p',
                                style: `margin-bottom: ${aSpacing[3]}px;
                                        margin-top: ${aSpacing[3]}px; 
                                        font-size: ${oFont.base}px; 
                                        line-height: ${oFont.lineHeight.p};`,
                                textContent: element2.case_content.p
                            })
                            let img1 = creation('img', articleContainer, {
                                src: element2.case_content.img,
                                style: `margin-bottom: ${aSpacing[3]}px; margin-top: ${aSpacing[3]}px;`,
                                className: "article__img"
                            })
                            let p2 = creation('p', articleContainer, {
                                className: 'base-font article__p',
                                style: `margin-bottom: ${aSpacing[3]}px;
                                        margin-top: ${aSpacing[3]}px;
                                        font-size:${oFont.base}px; 
                                        line-height: ${oFont.lineHeight.p};`,
                                textContent: element2.case_content.p2
                            })
                            let img2 = creation('img', articleContainer, {
                                src: element2.case_content.img2,
                                style: `margin-bottom: ${aSpacing[3]}px; margin-top: ${aSpacing[3]}px;`,
                                className: "article__img"
                            })
                            let p3 = creation('p', articleContainer, {
                                className: 'base-font article__p',
                                style: `margin-bottom: ${aSpacing[3]}px;
                                        margin-top: ${aSpacing[3]}px; 
                                        font-size: ${oFont.base}px;
                                        line-height: ${oFont.lineHeight.p};`,
                                textContent: element2.case_content.p3
                            })
                            let img3 = creation('img', articleContainer, {
                                src: element.case_content.img3,
                                style: `margin-bottom: ${aSpacing[3]}px; margin-top: ${aSpacing[3]}px;`,
                                className: "article__img"
                            })

                            let link = creation('a', articleContainer, {
                                className:"button article__link",
                                style: `background-color: ${element.color}`,
                                textContent: "Voir le projet",
                                href:element.link,
                            })
                            document.querySelector('.article__link').setAttribute("target", "_blank")


                            let buttonBackCreateElement = document.createElement('button')
                            let button_back = articleContainer.appendChild(buttonBackCreateElement)
                            button_back.textContent = "Back"
                            button_back.classList.add('article__button_back')
                            button_back.style.padding = "16px"
                            button_back.style.backgroundColor = "black"
                            button_back.style.cursor = "pointer"
                            
                            gsap.to(button_back, { 
                                display:"block",
                                opacity:1,
                                delay:1
                            })
                            button_back.addEventListener('click', () => {
                                for (let i = 0; i < projects.length; i++) {
                                    const element = projects[i];
                                }
                                gsap.to(main, {
                                    autoAlpha:1,
                                    x:0,
                                    duration:1
                                })
                                gsap.to(main, {
                                    display: "block",
                                    delay:1
                                })
    
                                gsap.to(nav, {
                                    autoAlpha:1,
                                    duration:1
                                })
                                gsap.to(nav, {
                                    display: "flex",
                                    delay:1
                                })
    
                                gsap.to(article, {
                                    autoAlpha:0,
                                    duration:1
                                })
                                gsap.to(button_back, {
                                    autoAlpha:0,
                                    duration:1
                                })
                                let currentBg = containerImg.children[1]
                                if (window.matchMedia("(min-width: 600px)").matches) {
                                    gsap.to(currentBg, {
                                        height:"670px",
                                        duration:0.3
                                    })
                                  } else {
                                    gsap.to(currentBg, {
                                        height:"calc(100% - 82px)",
                                        duration:0.3
                                    })
                                  }

                                let currentImg = containerImg.children[0]
                                gsap.to(currentImg, {
                                    x:0,
                                    duration:0.3
                                })
                                let summaryy = div.children[3]
                                gsap.to(summaryy, {
                                    x:0,
                                    duration:0.3
                                })
                                
                                let title = div.children[0]
                                gsap.to(title, {
                                    x:0,
                                    opacity:1,
                                    duration:0.3
                                })
                                let subtitle = div.children[1]
                                gsap.to(subtitle, {
                                    x:0,
                                    opacity:1,
                                    duration:0.3
                                })

                                setTimeout(() => {
                                    article.remove()
                                    button_back.remove()
                                }, 1000);
    
                        })

                        
                        })
                    })

                }
            }
        })
        .catch((err) => {
            console.log(err);
        })
    
    }

function projectInteractions() {

}
export { project }
