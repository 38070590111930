import { rN } from '../utils/fcts.js'
import { paths } from './lang'
import { langNavigator } from '../content/lang'
export var rPicture = rN(1,6)

function hello() {
    var helloDOM = document.querySelector('.hello')


    if(langNavigator.langCode == "en") {
        var aHello = [
            "Good morning",
            "Good Afternoon",
            "Good Evening"
        ]
    } else {
        var aHello = [
            "Bonjour",
            "Bonne après-midi",
            "Bonsoir"
        ]
    }
    // Good morning if it's in the morning
    var currentTime = new Date();
    var hours = currentTime.getHours();

    if(hours >= 5 && hours <= 12) {
        var helloo = aHello[0]
        helloDOM.textContent = helloo
    } else if(hours >= 12 && hours <= 17 ){
        var helloo = aHello[1]
        helloDOM.textContent = helloo
    } else if(hours > 17){
        var helloo = aHello[2]
        helloDOM.textContent = helloo
    }
    
    //Debug

}

export { hello } 